import * as React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Image, { FluidObject } from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import Title from '../components/title';
import ContentWidth from '../components/contentWidth';

type FlowNode = {
  title: string;
  content: string;
  image: { childImageSharp: { fluid: FluidObject } };
};

type Props = {
  location: Location;
  data: {
    flow: {
      edges: {
        node: FlowNode;
      }[];
    };
  };
};

function Flow({ location, data: { flow } }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.flow.path}
    >
      <SEO
        featuredImage={seoData.flow.featuredImage?.[defaultLangKey]}
        title={seoData.flow.title[defaultLangKey]}
        description={seoData.flow.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        <Title title={`留学までの流れ`} />
        <section css={tw`max-w-screen-md mx-auto`}>
          {flow.edges.map((item, index) => (
            <article key={index} css={tw`flex flex-col my-12 md:my-20`}>
              <header css={tw`flex items-center leading-none mb-4 md:mb-8`}>
                <span
                  css={tw`text-3xl md:text-5xl mr-3 md:mr-6 text-title italic font-medium`}
                >
                  {index + 1}.
                </span>
                <span css={tw`text-xl md:text-2xl font-bold`}>
                  {item.node.title}
                </span>
              </header>
              <div
                css={tw`md:flex w-full p-4 md:p-5 bg-gray-100 rounded overflow-hidden shadow-sm`}
              >
                <div
                  css={tw`bg-gray-200 md:w-1/3 mb-5 md:mb-0 md:mr-5 rounded overflow-hidden relative pb-9/16 md:pb-1/4`}
                >
                  <div css={tw`absolute top-0 left-0 right-0 bottom-0`}>
                    <Image
                      fluid={item.node.image.childImageSharp.fluid}
                      css={tw`h-full`}
                    />
                  </div>
                </div>
                <div
                  css={tw`md:w-2/3 flex items-center font-bold leading-relaxed md:leading-loose text-sm md:text-base`}
                >
                  {item.node.content}
                </div>
              </div>
            </article>
          ))}
        </section>
      </ContentWidth>
    </Layout>
  );
}

export const flowQuery = graphql`
  query {
    flow: allFlowJson {
      edges {
        node {
          title
          content
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default Flow;
